import React from 'react';
import Layout from '../components/layout';
import { injectIntl } from 'gatsby-plugin-intl';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import NewsCard from '../components/Cards/NewsCard';
import { Grid, Typography } from '@material-ui/core';
import Header from '../components/Header';
import COLORS from '../constants/colors';
import { shape, func } from 'prop-types';
import PageContainer from '../components/Containers/PageContainer';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: 20,
    paddingLeft: 20,
    color: COLORS.WHITE,
    width: '60%',
    maxWidth: 600,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 10,
    },
  },
  newsContainer: {
    margin: 0,
    marginTop: 35,
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  newsItem: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '15px !important',
      paddingBottom: '15px !important',
    },
  },
}));

const News = ({ intl, data }) => {
  const classes = useStyles();
  const { formatMessage } = intl;
  const siteTitle = data.site.siteMetadata.title;
  const news = data.allContentfulBlogPost.edges;

  return (
    <Layout>
      {/*TODO:Improve SEO and Helmet props values */}
      <SEO
        title="HeadzUp Sport AB"
        description="Nyheter om hockey och diskussion kring alvarliga tacklingar, träning samt proffs- och ungdomsishockey."
        keywords={['ishockey']}
      />
      <Helmet title={siteTitle} />
      <PageContainer>
        <Header secondary>
          {formatMessage({ id: 'news.header' })}
        </Header>
        <Typography className={classes.description}>
          {formatMessage({ id: 'news.description' })}
        </Typography>
        <Grid
          container
          spacing={10}
          className={classes.newsContainer}
        >
          {news.map(newsItem => (
            <Grid
              item
              key={newsItem.node.introText}
              className={classes.newsItem}
            >
              <NewsCard data={newsItem.node} />
            </Grid>
          ))}
        </Grid>
      </PageContainer>
    </Layout>
  );
};

News.propTypes = {
  intl: shape({ formatMessage: func.isRequired })
    .isRequired,
  data: shape().isRequired,
};

export default injectIntl(News);

export const pageQuery = graphql`
  query NewsQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          introText
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          postType
          linkUrl
          heroImage {
            fluid(
              maxWidth: 350
              maxHeight: 196
              resizingBehavior: SCALE
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
